import React from "react"
import style from "./smallGameCard.module.scss"
import { GatsbyImage } from "../GatsbyImage"
import { Link } from "gatsby"
function SmallGameCard({ data, gameLink }) {
  console.log(data,gameLink)
  console.log(gameLink)
  const slicedName = data.fullWidth
    ? data.name.slice(0, 33)
    : data.name.slice(0, 11)
  const link = gameLink ? `${gameLink}/${data.link}` : data.link
  return (
    <>
      {data.externalLink ? (
        <a
          className={data.fullWidth ? style.fullGameCard : style.gameCard}
          href={data.externalLink}
        >
          <GatsbyImage
            className={style.img}
            name={data.image}
            alt={data.name}
          />
          <div className={style.gameNameContainer}>
            <h2 className={style.gameName}>{`${slicedName} `}</h2>
            {/* <p className={style.subtext}>71k Plays</p> */}
          </div>
        </a>
      ) : (
        <Link
          className={data.fullWidth ? style.fullGameCard : style.gameCard}
          to={link}
        >
          <GatsbyImage
            className={style.img}
            name={data.image}
            alt={data.name}
          />
          <div className={style.gameNameContainer}>
            <h2 className={style.gameName}>{`${slicedName} `}</h2>
            {/* <p className={style.subtext}>71k Plays</p> */}
          </div>
        </Link>
      )}
    </>
  )
}

export default SmallGameCard
