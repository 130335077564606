import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Homelayoutcard from "../components/homelayoutcard/homelayout"
import style from "./layout.module.scss"
function HomeLayout({ children, data }) {
  return (
    <div>
      <Header data={data} />
      <div>{children}</div>
      <Footer data={data} />
    </div>
  )
}

const HomeLayoutComponent = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SidebarMenuQuery {
        allSidebarJson {
          edges {
            node {
              otherLinks {
                label
                path
              }
              menuLinks {
                path
                label
              }
              socialMediaLinks {
                link
                iconName
                label
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <HomeLayout
          data={data.allSidebarJson.edges[0].node}
          children={children}
        />
      </div>
    )}
  />
)

export default HomeLayoutComponent
