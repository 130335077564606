import React, { useState } from "react"
import ColorfulButton from "../colorfulButton"
import style from "./header.module.scss"
import { GatsbyImage } from "../GatsbyImage"
import SiteLogo from "../siteLogo"
import Sidemenu from "../sidemenu"
import { Link } from "gatsby"
// import Image from "../../assets/images/logo.png"
const menu = [
  { text: "Home", path: "/" },
  { text: "Arcade", path: "/arcade/" },
  { text: "Action", path: "/action/" },
  { text: "Puzzle", path: "/puzzle/" },
  { text: "Platform", path: "/platform/" },
  { text: "Racing", path: "/racing/" },
  { text: "Shooting", path: "/shooting/" },
]

function Header({ data }) {
  const [Open, setOpen] = useState(false)



  return (
    <div className={style.headerWrapper}>
      <Sidemenu open={Open} setOpen={setOpen} data={data} />
      <header className={style.header}>
        <div className={style.logoContainer}>
          <div onClick={() => setOpen(!Open)} className={style.mobileNavBtn}>


            { Open ? <span className={ style.Icon1Rotate} ></span> :
            <span className={style.Icon1} ></span>
            }
           { <span className={ !Open ? style.Icon2 : style.Icon3rotate} ></span>}
           { <span className={ Open ? style.Icon2rotate : style.Icon3 }></span>}


          </div>

          <SiteLogo />
        </div>
        <span>

        </span>
      </header>
      <header className={style.menuHeader}>
        {menu.map(m => (
          <Link to={m.path}>
            <ColorfulButton text={m.text} />
          </Link>
        ))}
      </header>
    </div>
  )
}

export default Header
