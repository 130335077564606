import React, { useState } from "react"
import style from "./homelayoutcard.module.scss"
import { GatsbyImage } from "../GatsbyImage"
import GameCard from "../smallGameCard"

import Image from "../../assets/images/game-charecters.png"
// import Icon from "../../assets/images/icons/play.svg"
const homelayoutcard = () => {
    const Data = {
        category:" TopGames",
        externalLink:null,
        fullWidth:true,
        id:"536bda17-4606-5bb3-80f3-d7aca539bafd",
        image:"squid_game_square.jpeg",
        link:"squid-game",
        name:"Squid Game Survival Challenge",
    }

    return (
        <div className={style.herocontent}>
            <h4>Enjoy The Games</h4>
            <h1>Epic Games Made For True Gamers!
            </h1>
            <div className={style.image}>
                {/* <div className={style.playbtn}>
             <div className={style.Iocnimge}>
             <img src={Icon} className={style.Iocnimge}/>
             </div>
             <div className={style.ripple}>

             </div>
        </div> */}
                <GameCard data={Data} key={1} gameLink={"-topgames-"} />

                {/* <img src={Image} /> */}

                {/* <iframe width="100%" height="100%" src="https://www.youtube.com/embed/eXi6Y7it3uA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>

        </div>
    )
}
export default homelayoutcard