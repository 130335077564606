import React from "react"
import { Link } from "gatsby"
import SiteLogo from "../siteLogo"
import { GatsbyImage } from "../GatsbyImage"
import style from "./sidemenu.module.scss"

function Sidemenu({ open, setOpen, data }) {
  const activeStyle = {
    background: "rgb(50 58 106 / 55%)",
    color: "white",
    fontWeight: "600",
  }
  return (
    <div
      onClick={() => setOpen(false)}
      style={{ top: open ? "77px" : "-100%" }}
      className={`${style.sidemenuWrapper} open`}
    >
      <div onClick={e => e.stopPropagation()} className={style.menuWrapper}>
        {/* <div className={style.head}>
          <SiteLogo />
          <div className={style.closeContainer}>
           
          </div>
        </div> */}
        <div className={style.menuContainer}>
          {data.menuLinks.map(m => (
            <Link
              partiallyActive={m.label === "Home" ? false : true}
              to={m.path}
              className={`${style.menuText}`}
              activeStyle={activeStyle}
            >
              {m.label}
            </Link>
          ))}
        </div>
        <div className={style.menuContainer}>
          {data.otherLinks.map(m => (
            <Link
              to={m.path}
              className={`${style.menuText}`}
              activeStyle={activeStyle}
            >
              {m.label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Sidemenu
