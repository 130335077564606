import React from "react"
import style from "./siteLogo.module.scss"
import { Link } from "gatsby"

import { GatsbyImage } from "../GatsbyImage"
import Icon from "../../assets/images/gamesop.png"
function SiteLogo() {
  return (

<Link to={"/"}>
    <div className={style.siteLogo}>
     
     <img src={Icon} />
    </div>
    </Link>
  )
}

export default SiteLogo
